// import { Tooltip } from "chart.js";
// import React, { useEffect, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
// import { stripeDoughnutChartPlugin } from "../../Screens/Test/stripePlugin";

export function DoughnutChart(props) {
  // const customToolTip = useRef(null);

  const customOptions = {
    elements: {
      arc: {
        borderWidth: 2,
        hoverBorderWidth: 3,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      // tooltip: {
      //   enabled: false,
      //   callbacks: {
      //     label: function(context) {
      //       const label = context.label || "";
      //       const value = context.raw || "";
      //       return `${label}: ${value} ${props.units || ""}`;
      //     },
      //   },
      //   position: "average",
      //   external: function(context) {
      //     const tooltip = context.tooltip;
      //     const chart = context.chart;
      //     const chartPosition = chart.canvas.getBoundingClientRect();

      //     if (!customToolTip.current) {
      //       customToolTip.current.style.width = `${chartPosition.width}px`;
      //       customToolTip.current.style.position = "absolute";
      //       customToolTip.current.style.fontSize = "12px";
      //       customToolTip.current.style.whiteSpace = "no-wrap";
      //       customToolTip.current.style.display = "flex";
      //       customToolTip.current.style.flexDirection = "column";
      //       customToolTip.current.style.justifyContent = "center";
      //       customToolTip.current.style.alignItems = "center";
      //       customToolTip.current.style.color = "#fff";
      //       customToolTip.current.style.padding = "5px";
      //       customToolTip.current.style.borderRadius = "3px";
      //       customToolTip.current.style.pointerEvents = "none";
      //     }

      //     customToolTip.current.innerHTML = tooltip.body
      //       ?.map((bodyItem) => bodyItem.lines)
      //       .join("<br>");

      //     customToolTip.current.style.left = `${chartPosition.left +
      //       window.pageXOffset}px`;

      //     customToolTip.current.style.top = `${chartPosition.bottom +
      //       window.pageYOffset +
      //       16}px`;

      //     if (tooltip.opacity === 0) {
      //       customToolTip.current.innerHTML = props.data.defaultValue;
      //     }
      //   },
      // },
    },
    cutout: "50%",
    responsive: true,
    maintainAspectRatio: false,
  };

  // useEffect(() => {
  //   if (customToolTip.current && props.data.defaultValue) {
  //     customToolTip.current.style.fontSize = "12px";
  //     customToolTip.current.innerHTML = props.data.defaultValue;
  //   }
  //   // eslint-disable-next-line
  // }, [props.data.defaultValue]);

  return (
    <div className="inherit-parent-height inherit-parent-width flex-align-items-center flex-direction-column position-relative">
      <div
        style={{
          position: "absolute",
          width: "100%",
          justifyContent: "center",
          display: "flex",
          top: "-24px",
        }}
        className="font-size-medium text-align-center "
      >
        {props.title}
      </div>
      <div className="height-90-percentage width-50-percentage margin-top-large display-flex flex-align-items-center">
        <Doughnut
          data={props.data}
          options={customOptions}
          // plugins={[stripeDoughnutChartPlugin]}
          plugins={[]}
          style={props.style}
          className="inherit-parent-width height-width"
        />
        <div className="font-color-white width-50-percentage">
          {props.legend}
        </div>
      </div>
      {/* <div
        ref={customToolTip}
        style={{
          marginTop: "16px",
          marginBottom: "16px",
        }}
      /> */}
    </div>
  );
}
