import { useEffect, useRef } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { isValidArray, isValidObject } from "./validators";
import greenMarker from "../Assets/greenMarker.svg";
import { dateAndTimeConverter, tableTitles } from "../Utils/constants";

export default function useMap(
  locations,
  container,
  mapContainerRendered,
  isAuth,
  markerOnclick,
  tracks,
  employees,
  selectedTable
) {
  const map = useRef({
    Map: null,
    AdvancedMarkerElement: null,
    loading: false,
  });
  const markers = useRef({});
  const clustered = useRef(null);
  const isInitialized = useRef(false);
  const directionsService = useRef(null);
  const directionsDisplays = useRef([]);
  const polylineRef = useRef([]);
  const markerRef = useRef([]);
  const customOverlayRef = useRef([]);

  useEffect(() => {
    if (
      isAuth &&
      mapContainerRendered &&
      !map.current.Map &&
      !map.current.loading
    ) {
      loadMapsAndMarkers(container.current);
    }
    // eslint-disable-next-line
  }, [isAuth, mapContainerRendered]);

  useEffect(() => {
    if (isInitialized.current) {
      markLocations();
    }
    // eslint-disable-next-line
  }, [locations]);

  const clearRoutes = () => {
    directionsDisplays.current.forEach((display) => {
      display.setMap(null);
    });
    polylineRef.current.forEach((display) => {
      display.setMap(null);
    });
    markerRef.current.forEach((display) => {
      display.start.setMap(null);
      display.end.setMap(null);
    });
    directionsDisplays.current = [];
    polylineRef.current = [];
    markerRef.current = [];
  };

  useEffect(() => {
    if (!isInitialized.current) {
      return;
    }
    if (selectedTable !== tableTitles.workForce) {
      clearRoutes();
      return;
    }
    const employeeData = Object.values(employees);
    if (isValidObject(tracks)) {
      Object.entries(tracks).forEach(
        ([key, { tracks: trackLocations, color }]) => {
          initializeMap(
            trackLocations,
            color,
            employeeData.find((data) => data.phoneNumber === `+91${key}`)
          );
        }
      );
    } else {
      clearRoutes();
    }

    // eslint-disable-next-line
  }, [tracks, isInitialized, employees, selectedTable]);

  const loadMapsAndMarkers = async (mapContainer) => {
    if (map.current.loading || isInitialized.current) {
      return;
    }
    // eslint-disable-next-line no-undef
    const { Map } = await google.maps.importLibrary("maps");
    // eslint-disable-next-line no-undef
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    map.current.loading = true;
    const mapOptions = {
      center: { lat: 13.048129476915838, lng: 80.2305531172708 },
      disableDefaultUI: true,
      fullscreenControl: false,
      mapId: "8faf43cc4913ee0d",
      mapTypeControl: false,
      mapTypeControlOptions: false,
      mapTypeId: window.google.maps.MapTypeId?.ROADMAP,
      minZoom: 8,
      panControl: false,
      scaleControl: false,
      scrollwheel: true,
      streetViewControl: false,
      zoom: 12,
      zoomControl: false,
    };

    map.current.Map = new Map(mapContainer, mapOptions);
    map.current.AdvancedMarkerElement = AdvancedMarkerElement;
    map.current.loading = false;
    isInitialized.current = true;

    markLocations();
  };

  const calcRoute = async (
    start,
    end,
    wayPts,
    directionsService,
    color,
    AdvancedMarkerElement,
    directionsDisplay,
    overallBounds,
    userData
  ) => {
    const request = {
      origin: start,
      destination: end,
      waypoints: wayPts,
      optimizeWaypoints: true,
      provideRouteAlternatives: false,
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (response, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        try {
          directionsDisplay?.setDirections(response);
          const route = response.routes[0].legs.flatMap((leg) =>
            leg.steps.flatMap((step) => step.path)
          );
          const polyline = new window.google.maps.Polyline({
            path: route,
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 4,
            padding: 4,
            zIndex: 1,
            icons: [
              {
                icon: {
                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  scale: 2,
                  strokeColor: "#FFFFF",
                },
                offset: "100%",
                repeat: "200px",
              },
            ],
          });

          route.forEach((latLng) => overallBounds.extend(latLng));

          polyline.setMap(map.current.Map);
          let customOverlay = null;

          polyline.addListener("mouseover", (event) => {
            polyline.setOptions({ zIndex: 10 });
            customOverlay = createCustomOverlay(
              map.current.Map,
              event.latLng,
              content(userData.name, userData.phoneNumber, start.data.timestamp)
            );
            customOverlayRef.current.push(customOverlay);
          });

          polyline.addListener("mouseout", () => {
            if (customOverlay) {
              customOverlay.onRemove();
              customOverlayRef.current = [];
            }
          });
          polylineRef.current.push(polyline);
          const markers = createRouteMarkers(
            start,
            end,
            map.current.Map,
            AdvancedMarkerElement
          );

          markerRef.current.push(markers);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const initializeMap = async (locations, color, userData) => {
    if (!directionsService.current) {
      directionsService.current = new window.google.maps.DirectionsService();
    }

    if (!locations || locations.length === 0) {
      clearRoutes();
      return;
    }
    const directionsDisplay = new window.google.maps.DirectionsRenderer({
      suppressMarkers: true,
      suppressPolylines: true,
      preserveViewport: true,
      map: map.current.Map,
    });

    directionsDisplay.setMap(map.current.Map);

    directionsDisplays.current.push(directionsDisplay);

    const flightPlanCoordinates = [];
    const bounds = new window.google.maps.LatLngBounds();

    for (let i = 0; i < locations?.length; i++) {
      const lat = parseFloat(locations[i].lat);
      const lng = parseFloat(locations[i].lon);
      if (!isNaN(lat) && !isNaN(lng)) {
        flightPlanCoordinates.push({
          lat,
          lng,
          data: locations[i],
        });
        bounds.extend({ lat, lng });
      }
    }

    const start = {
      location: flightPlanCoordinates[0],
      stopover: false,
    };
    const end = {
      location: flightPlanCoordinates[flightPlanCoordinates.length - 1],
      stopover: false,
    };
    const wayPts = [];
    for (let i = 1; i < flightPlanCoordinates.length - 1; i++) {
      wayPts.push({
        location: flightPlanCoordinates[i],
        stopover: false,
      });
    }

    if (isValidArray(wayPts)) {
      const optimizedWaypoints = await snapToRoad(
        [start, ...selectElements(wayPts), end],
        false
      );

      const routePromises = [];
      const overallBounds = new window.google.maps.LatLngBounds();
      routePromises.push(
        calcRoute(
          {
            lat: start.location.lat,
            lng: start.location.lng,
            data: start.location.data,
          },
          {
            lat: end.location.lat,
            lng: end.location.lng,
            data: end.location.data,
          },
          removeMinimumDistance(optimizedWaypoints, 500),
          directionsService.current,
          color,
          map.current.AdvancedMarkerElement,
          directionsDisplay,
          overallBounds,
          userData
        )
      );

      await Promise.all(routePromises);
      // map.current.Map.fitBounds(overallBounds);
    }
  };

  const markLocations = () => {
    if (!isValidArray(locations) || !map.current.Map) {
      return;
    }
    const infoWindow = new window.google.maps.InfoWindow();

    // check because of this memory increases or not
    // Object.values(markers.current).forEach((marker) => marker.setMap(null));
    // markers.current = {};

    const newMarkers = locations
      .map((data) => {
        if (!data || markers.current[data.documentId]) {
          return null;
        }

        const imageElement = document.createElement("img");
        imageElement.setAttribute("src", greenMarker);

        const marker = new map.current.AdvancedMarkerElement({
          map: map.current.Map,
          position: {
            lat: data._geoloc?.lat,
            lng: data._geoloc?.lng,
          },
          title: `${data?.PCTNo?.toString()?.padStart(2, "0")} - ${
            data?.location
          }`,
          content: imageElement,
          zIndex: 1,
        });

        marker.addListener("click", () => {
          infoWindow.close();
          infoWindow.setContent(createInfoWindowContent(data));
          infoWindow.open(map.current.Map, marker);
        });

        markers.current[data.documentId] = marker;
        return marker;
      })
      .filter(Boolean);

    setupCluster(newMarkers);
    setupInfoWindowListener(infoWindow);
    map.current.Map.addListener("click", () => {
      infoWindow.close();
    });
  };

  const setupCluster = (newMarkers) => {
    if (!newMarkers || newMarkers.length === 0) {
      return;
    }

    if (!clustered.current) {
      clustered.current = new MarkerClusterer({
        map: map.current.Map,
        markers: newMarkers,
        renderer: {
          render({ count, position }) {
            const imageElement = document.createElement("img");
            imageElement.setAttribute("src", greenMarker);
            const clusterScale = Math.min(3, 1 + count / 8);
            imageElement.style.transform = `scale(${clusterScale})`;
            imageElement.style.zIndex = "1";
            return new map.current.AdvancedMarkerElement({
              position,
              content: imageElement,
              title: `${count}`,
              zIndex: 1,
            });
          },
          zIndex: 1,
        },
      });
    } else {
      clustered.current.clearMarkers();
      clustered.current.addMarkers(newMarkers);
    }
  };

  const setupInfoWindowListener = (infoWindow) => {
    window.google.maps.event.addListener(infoWindow, "domready", () => {
      const customClickElement = document.getElementById("custom-click");
      if (customClickElement) {
        customClickElement.addEventListener("click", () => {
          markerOnclick(customClickElement.getAttribute("name"));
        });
      }
    });
  };

  const createRouteMarkers = (start, end) => {
    const startIconElement = document.createElement("div");
    startIconElement.style.zIndex = "200";
    startIconElement.innerHTML = `<img src="${window.location.origin}/Arrival-Green.svg" width="14" height="14" alt="Start Icon">`;

    const endIconElement = document.createElement("div");
    endIconElement.style.zIndex = "200";
    endIconElement.innerHTML = `<img src="${window.location.origin}/Departure-Red.svg" width="14" height="14" alt="End Icon">`;

    const startMaker = new map.current.AdvancedMarkerElement({
      position: start,
      map: map.current.Map,
      content: startIconElement,
      zIndex: 1,
      title: "Starting Point",
    });

    const endMarker = new map.current.AdvancedMarkerElement({
      position: end,
      map: map.current.Map,
      content: endIconElement,
      zIndex: 1,
      title: "Ending Point",
    });
    return { start: startMaker, end: endMarker };
  };
}

const createInfoWindowContent = (data) => {
  return `<div style="display:flex;z-index: 1000;font-family:aldrich-regular;">
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; top: -1px; left: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; top: -1px; right: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; bottom: -1px; left: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; bottom: -1px; right: -1px;"></div>

      <div>
        <div style="height:100%;">
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="80" height="80" fill="#565656" fill-opacity="0.3" />
            <path
              d="M8 16V64H16V41.8947H26.0063H28V64H35.0094L44 16H8Z"
              fill="#909090"
            />
            <path
              d="M48 16L38.8936 64H52V42L64.4731 41.8947V64H72.9996V16H48Z"
              fill="#909090"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.0114 30.5906C21.9082 30.5906 22.6353 29.852 22.6353 28.9409C22.6353 28.0298 21.9082 27.2912 21.0114 27.2912C20.1145 27.2912 19.3874 28.0298 19.3874 28.9409C19.3874 29.852 20.1145 30.5906 21.0114 30.5906ZM21.0114 32C22.6745 32 24.0227 30.6304 24.0227 28.9409C24.0227 27.2514 22.6745 25.8818 21.0114 25.8818C19.3482 25.8818 18 27.2514 18 28.9409C18 30.6304 19.3482 32 21.0114 32Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.2518 22.1131L28 22V26.6414L26.6126 26.6414V24.4256L23.0056 28.0988L22.0234 27.1034L25.5943 23.4671L23.2843 23.5221L23.2518 22.1131Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M62.1387 24.0693C62.1387 25.7645 60.7645 27.1387 59.0693 27.1387C57.3742 27.1387 56 25.7645 56 24.0693C56 22.3742 57.3742 21 59.0693 21C60.7645 21 62.1387 22.3742 62.1387 24.0693ZM59.0693 26.1156C60.1994 26.1156 61.1156 25.1994 61.1156 24.0693C61.1156 22.9392 60.1994 22.0231 59.0693 22.0231C57.9392 22.0231 57.0231 22.9392 57.0231 24.0693C57.0231 25.1994 57.9392 26.1156 59.0693 26.1156Z"
              fill="white"
            />
            <path
              d="M58.5578 26.6271H59.5809V33H58.5578V26.6271Z"
              fill="white"
            />
            <path
              d="M61.6271 29.1849V28.1618H56.5116V29.1849H61.6271Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div style="padding-left:16px;width:100%">
        <div style="display:flex;justify-content:space-between;">
          <div style="font-size:14px;text-transform:uppercase;white-space:nowrap;padding-right:64px;">
            ${data?.location || "No ID Available"}
          </div>
          <div
            id="custom-click"
            name="${data.documentId}"
            style="cursor:pointer;"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1 1H9.6V2.2H2.2V13.8H13.8V6.4H15V15H1V1Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.8966 1.09712L14.8907 1.09712V5.09123H13.6907V3.18815L9.43822 7.61638L8.5727 6.7852L12.8827 2.29712L10.8966 2.29712V1.09712Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <div style="padding-top:8px;font-size:10px;justify-content:space-between;display:flex;flex-direction:column;height:60px">
          <div style="">PCT : ${data?.PCTNo}</div>
          <div style="">ZONE : ${data?.zone}</div>
          <div style="">WARD : ${data?.ward}</div>
        </div>
      </div>
    </div>`;
};

const removeMinimumDistance = (dataArray, minDistanceThreshold) => {
  let filteredData = [dataArray[0]];

  for (let i = 1; i < dataArray.length - 1; i++) {
    const currPoint = dataArray[i];
    const nextPoint = dataArray[i + 1];

    const dist = calculateDistance(
      { lat: parseFloat(currPoint.lat), lon: parseFloat(currPoint.lon) },
      { lat: parseFloat(nextPoint.lat), lon: parseFloat(nextPoint.lon) }
    );

    if (dist >= minDistanceThreshold) {
      filteredData.push(currPoint);
    }
  }

  filteredData.push(dataArray[dataArray.length - 1]);

  return filteredData;
};

function calculateDistance(location1, location2) {
  const deg2rad = (deg) => deg * (Math.PI / 180);

  if (location1 && location2) {
    const R = 6371;
    const dLat = deg2rad(location2.lat - location1.lat);
    const dLon = deg2rad(location2.lon - location1.lon);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(location1.lat)) *
        Math.cos(deg2rad(location2.lat)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = Math.round(R * c * 1000);
    return distance;
  }
  return 0;
}

function selectElements(array) {
  const n = array.length;
  const result = [];

  if (n <= 100) {
    return array;
  }

  result.push(array[0]);

  const middleCount = 90 - 2;
  const step = (n - 2) / middleCount;

  for (let i = 1; i <= middleCount; i++) {
    result.push(array[Math.round(i * step)]);
  }

  result.push(array[n - 1]);

  return result;
}

const createCustomOverlay = (map, position, content) => {
  let div = null;

  const overlay = new window.google.maps.OverlayView();

  overlay.onAdd = () => {
    div = document.createElement("div");
    div.style.position = "absolute";
    div.style.width = "240px";
    div.style.height = "fit-content";
    div.style.zIndex = "1000";
    div.style.padding = "16px";
    div.style.color = "white";
    div.style.backgroundColor = "#020d23";
    div.style.border = "solid 1px grey";

    div.innerHTML = content;

    const panes = overlay.getPanes();
    panes.overlayMouseTarget.appendChild(div);
  };

  overlay.draw = () => {
    const overlayProjection = overlay.getProjection();
    const pixelPosition = overlayProjection.fromLatLngToDivPixel(position);

    if (div) {
      div.style.left = `${pixelPosition.x}px`;
      div.style.top = `${pixelPosition.y}px`;
    }
  };

  overlay.onRemove = () => {
    if (div) {
      div.parentNode.removeChild(div);
      div = null;
    }
  };

  overlay.setMap(map);
  return overlay;
};

const snapToRoad = (path, stopover) => {
  return new Promise((resolve, reject) => {
    const pathStr = path
      .map((point) => `${point.location?.lat},${point.location?.lng}`)
      .join("|");

    const url = `https://roads.googleapis.com/v1/snapToRoads?path=${pathStr}&interpolate=true&key=${process.env.REACT_APP_MAP_KEY}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.snappedPoints) {
          const snappedCoordinates = data.snappedPoints.map((point) => ({
            location: {
              lat: point.location.latitude,
              lng: point.location.longitude,
            },
            stopover: stopover,
          }));
          resolve(snappedCoordinates);
        } else {
          reject("No snapped points found");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const content = (name, phoneNumber, timestamp) => {
  return `<div style="z-index:1000;font-family:aldrich-regular;">
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; top: -1px; left: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; top: -1px; right: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; bottom: -1px; left: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; bottom: -1px; right: -1px;"></div>
      <div style="display:flex">
        <div style="padding-right:8px">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" fill="#565656" fill-opacity="0.3" />
            <path
              d="M19.8948 18.5919C18.8792 16.7442 17.2933 15.3045 15.3924 14.5045C16.3377 13.7696 17.0359 12.745 17.3882 11.5759C17.7406 10.4068 17.7291 9.15238 17.3554 7.99039C16.9818 6.8284 16.2649 5.81774 15.3063 5.10156C14.3477 4.38538 13.1961 4 12.0144 4C10.8328 4 9.68116 4.38538 8.72258 5.10156C7.76401 5.81774 7.04711 6.8284 6.67345 7.99039C6.29979 9.15238 6.28831 10.4068 6.64063 11.5759C6.99295 12.745 7.69121 13.7696 8.6365 14.5045C6.73562 15.3045 5.14971 16.7442 4.13407 18.5919C4.07114 18.6968 4.0292 18.8138 4.01073 18.9358C3.99227 19.0578 3.99767 19.1825 4.0266 19.3023C4.05554 19.4221 4.10742 19.5347 4.17916 19.6333C4.25091 19.7319 4.34105 19.8146 4.44423 19.8764C4.54741 19.9382 4.66153 19.9779 4.77979 19.993C4.89805 20.0082 5.01805 19.9986 5.13265 19.9647C5.24725 19.9308 5.35411 19.8734 5.44688 19.7959C5.53965 19.7184 5.61643 19.6223 5.67266 19.5134C7.01495 17.1084 9.38543 15.674 12.0144 15.674C14.6434 15.674 17.0139 17.1092 18.3562 19.5134C18.4781 19.7165 18.6714 19.8625 18.8952 19.9206C19.1191 19.9787 19.356 19.9443 19.5558 19.8246C19.7556 19.7049 19.9027 19.5094 19.9659 19.2794C20.0292 19.0493 20.0037 18.8028 19.8948 18.5919ZM8.16241 9.83815C8.16241 9.04842 8.38832 8.27643 8.81159 7.61979C9.23486 6.96315 9.83646 6.45136 10.5403 6.14915C11.2442 5.84693 12.0187 5.76786 12.7659 5.92193C13.5132 6.076 14.1995 6.45629 14.7382 7.01471C15.2769 7.57313 15.6438 8.28461 15.7925 9.05917C15.9411 9.83372 15.8648 10.6366 15.5732 11.3662C15.2817 12.0958 14.788 12.7194 14.1545 13.1582C13.521 13.5969 12.7763 13.8311 12.0144 13.8311C10.9932 13.8299 10.0141 13.4088 9.29194 12.6602C8.5698 11.9117 8.16358 10.8968 8.16241 9.83815Z"
              fill="white"
            />
          </svg>
        </div>
        <div style="padding-bottom:16px">
          <div style="text-transform:uppercase;font-size:10px;">${name}</div>
          <div style="padding-top:4px;font-size:14px;">${phoneNumber}</div>
        </div>
      </div>

      <div style="display: flex; justify-content: space-between;">
        <div style="display: flex; align-items: center; flex-direction: column;">
          <div style="font-size:10px;">TIME</div>
          <div style="padding-top:8px;">
            ${dateAndTimeConverter(timestamp, "Time")}
          </div>
        </div>
        <div style="display: flex; align-items: center; flex-direction: column;">
          <div style="font-size:10px;" >DIRECTION</div>

          <div style="transform: rotate(180deg);padding-top:8px;">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4564 7.5L2.02231 7.5C1.88394 7.5 1.75125 7.55267 1.65341 7.64643C1.55557 7.74018 1.50061 7.86735 1.50061 7.99994C1.50061 8.13253 1.55557 8.25969 1.65341 8.35345C1.75125 8.4472 1.88394 8.49987 2.02231 8.49987H12.4564C12.5947 8.49987 12.7274 8.4472 12.8253 8.35345C12.9231 8.25969 12.9781 8.13253 12.9781 7.99994C12.9781 7.86735 12.9231 7.74018 12.8253 7.64643C12.7274 7.55267 12.5947 7.5 12.4564 7.5Z"
                fill="white"
              />
              <path
                d="M12.2405 8L7.91349 12.1455C7.81553 12.2394 7.76049 12.3667 7.76049 12.4994C7.76049 12.6322 7.81553 12.7595 7.91349 12.8534C8.01145 12.9473 8.14431 13 8.28285 13C8.42139 13 8.55426 12.9473 8.65222 12.8534L13.3475 8.35396C13.3961 8.30752 13.4347 8.25235 13.461 8.19161C13.4873 8.13087 13.5008 8.06576 13.5008 8C13.5008 7.93424 13.4873 7.86913 13.461 7.80839C13.4347 7.74765 13.3961 7.69248 13.3475 7.64604L8.65222 3.14661C8.55426 3.05274 8.42139 3 8.28285 3C8.14431 3 8.01145 3.05274 7.91349 3.14661C7.81553 3.24049 7.76049 3.36781 7.76049 3.50057C7.76049 3.63333 7.81553 3.76065 7.91349 3.85452L12.2405 8Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div style="display: flex; align-items: center; flex-direction: column;">
          <div style="font-size:10px;" >DATE</div>
          <div style="padding-top:8px;">
            ${dateAndTimeConverter(timestamp, "secondDate")}
          </div>
        </div>
      </div>
    </div>`;
};
