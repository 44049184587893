import React, { useEffect, useRef, useState } from "react";
import { isValidArray } from "../../Services/validators";
import Modal from "../../Components/Modal/Modal";
import InputBox from "../../Components/InputBox/InputBox";
import Button from "../../Components/Button/Button";
import { connect } from "react-redux";
import { clearTracksData, setTracksData } from "../../Redux/track/action";
import { InfoIcon, ProcedureEditIcon } from "../../Assets/assets";
import { darkModeStyle, dateAndTimeConverter } from "../../Utils/constants";

function LocationTracking(props) {
  const [showModal, setShowModal] = useState(true);
  const [isTrackingDataPresent, setIsTrackingDataPresent] = useState(true);
  const [formData, setFormData] = useState({
    phoneNumber: "+91",
    date: new Date().toISOString().split("T")[0],
  });
  const mapContainer = useRef(null);

  useEffect(() => {
    // props.clearTracksData();
    props.setTracksData();
    setShowModal(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let locationOfEmployees = {};
    props.tracks.data.forEach((track) => {
      const phoneNumber = track.phoneNumber.replace(/\+91/gi, "");

      if (!locationOfEmployees[phoneNumber]) {
        locationOfEmployees[phoneNumber] = {
          tracks: [track],
          color: generateAestheticColor(),
        };
      } else {
        locationOfEmployees[phoneNumber].tracks.push(track);
      }
    });

    // const locations = isValidArray(props.tracks.data)
    //   ? filterData(
    //       JSON.parse(
    //         JSON.stringify(
    //           props.tracks.data?.sort((a, b) => a.timestamp - b.timestamp)
    //         )
    //       ),
    //       getValidDistance(props.tracks.data)
    //     )
    //   : [];

    let directionsDisplay;
    let map;

    const calcRoute = async (
      start,
      end,
      wayPts,
      directionsService,
      color,
      AdvancedMarkerElement
    ) => {
      const request = {
        origin: start,
        destination: end,
        waypoints: wayPts,
        optimizeWaypoints: true,
        provideRouteAlternatives: false,
        travelMode: window.google.maps.TravelMode.DRIVING,
      };

      directionsService.route(request, (response, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          try {
            directionsDisplay?.setDirections(response);
            // const route = response.routes[0].overview_path;
            // Extract the detailed route path from the response
            const route = response.routes[0].legs.flatMap((leg) =>
              leg.steps.flatMap((step) => step.path)
            );
            // Create a polyline to display the route with a custom color
            const polyline = new window.google.maps.Polyline({
              path: route,
              geodesic: true,
              // strokeColor: "#FF5733", // Custom color
              strokeColor: color,
              strokeOpacity: 1.0,
              strokeWeight: 4,
              padding: 4,
              zIndex: 0,
              icons: [
                {
                  icon: {
                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    scale: 2,
                    strokeColor: "#FFFFF",
                  },
                  offset: "100%",
                  repeat: "200px",
                },
              ],
            });

            polyline.setMap(map);
            let customOverlay = null;

            polyline.addListener("mouseover", (event) => {
              customOverlay = createCustomOverlay(
                map,
                event.latLng,
                content(
                  props.tracks?.selectedEmployee?.name,
                  start.data.phoneNumber,
                  start.data.timestamp
                )
              );
            });

            polyline.addListener("mouseout", () => {
              if (customOverlay) {
                customOverlay.onRemove();
                customOverlay = null;
              }
            });
          } catch (error) {
            console.error(error);
          }
        }

        const startIconElement = document.createElement("div");
        startIconElement.innerHTML = `<img src="${window.location.origin}/Arrival-Green.png" width="40" height="40" alt="Start Icon">`;

        const endIconElement = document.createElement("div");
        endIconElement.innerHTML = `<img src="${window.location.origin}/Departure-Red.png" width="40" height="40" alt="End Icon">`;

        new AdvancedMarkerElement({
          position: start,
          map: map,
          content: startIconElement,
          zIndex: 1,
          title: "Starting Point",
        });
        new AdvancedMarkerElement({
          position: end,
          map: map,
          content: endIconElement,
          zIndex: 1,
          title: "Ending Point",
        });
      });
    };

    const initializeMap = async (locations, color) => {
      // eslint-disable-next-line no-unused-vars, no-undef
      const { Map } = await google.maps.importLibrary("maps");
      // eslint-disable-next-line no-unused-vars, no-undef
      const { AdvancedMarkerElement } = await google.maps.importLibrary(
        "marker"
      );
      map = new Map(mapContainer.current, {
        mapTypeId: window.google.maps.MapTypeId?.ROADMAP,
        scrollwheel: true,
        scaleControl: false,
        fullscreenControl: false,
        mapTypeControlOptions: false,
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false,
        panControl: false,
        styles: darkModeStyle,
      });

      const directionsService = new window.google.maps.DirectionsService();
      directionsDisplay = new window.google.maps.DirectionsRenderer({
        suppressMarkers: true,
        suppressPolylines: true,
        map: map,
      });

      directionsDisplay.setMap(map);

      // const infoWindow = new window.google.maps.InfoWindow();
      const flightPlanCoordinates = [];
      const bounds = new window.google.maps.LatLngBounds();

      for (let i = 0; i < locations?.length; i++) {
        const lat = parseFloat(locations[i].lat);
        const lng = parseFloat(locations[i].lon);
        if (!isNaN(lat) && !isNaN(lng)) {
          // const marker = new window.google.maps.Marker({
          //   position: { lat, lng },
          //   map: map,
          // });
          flightPlanCoordinates.push({
            lat,
            lng,
            data: locations[i],
          });
          bounds.extend({ lat, lng });

          // window.google.maps.event.addListener(
          //   marker,
          //   "click",
          //   // eslint-disable-next-line no-loop-func
          //   ((marker, i) => {
          //     return () => {
          //       console.log(locations);
          //       infoWindow.setContent(locations[i]["phoneNumber"]);
          //       infoWindow.open(map, marker);
          //     };
          //   })(marker, i)
          // );
        }
      }

      map.fitBounds(bounds);

      const start = {
        location: flightPlanCoordinates[0],
        stopover: false,
      };
      const end = {
        location: flightPlanCoordinates[flightPlanCoordinates.length - 1],
        stopover: false,
      };
      const wayPts = [];
      for (let i = 1; i < flightPlanCoordinates.length - 1; i++) {
        wayPts.push({
          location: flightPlanCoordinates[i],
          stopover: false,
        });
      }
      // calcRoute(start, end, wayPts, directionsService);
      if (isValidArray(wayPts)) {
        const optimizedWaypoints = await snapToRoad(
          [start, ...selectElements(wayPts), end],
          false
        );
        calcRoute(
          {
            lat: start.location.lat,
            lng: start.location.lng,
            data: start.location.data,
          },
          {
            lat: end.location.lat,
            lng: end.location.lng,
            data: end.location.data,
          },
          removeMinimumDistance(optimizedWaypoints, 500),
          directionsService,
          color,
          AdvancedMarkerElement
        );
      }
    };

    for (const key of Object.keys(locationOfEmployees)) {
      console.log(key, typeof key, "key");
      // if (key !== "8939263127") {
      initializeMap(
        locationOfEmployees[key].tracks,
        locationOfEmployees[key].color
      );
      // }
    }

    if (isValidArray(props.tracks.data)) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }

    if (props.tracks.data !== null && !isValidArray(props.tracks.data)) {
      setIsTrackingDataPresent(false);
    } else {
      setIsTrackingDataPresent(true);
    }
  }, [props.tracks.data, props.tracks?.selectedEmployee]);

  return (
    <div className="remaining-body-height-from-header">
      {window.location.pathname === "/locationTracking" && !showModal && (
        <>
          <div
            style={{
              position: "absolute",
              top: "98px",
            }}
            className="height-fit-content width-fit-content position-absolute z-index-1 bottom-32px left-32px"
          >
            <InfoCard
              onEditClick={() => {
                setShowModal(true);
                if (props.tracks?.selectedEmployee?.phoneNumber)
                  setFormData({
                    ...formData,
                    phoneNumber: props.tracks?.selectedEmployee?.phoneNumber,
                  });
              }}
              theme={props.theme}
              data={props.tracks.selectedEmployee}
              formData={formData}
            />
          </div>
        </>
      )}
      <div
        ref={mapContainer}
        className="inherit-parent-height inherit-parent-width over-flow-hidden"
        data-cy="location-tracking-map"
      />

      <Modal
        show={showModal}
        canIgnore={false}
        onClose={() => {
          // setShowModal(false);
        }}
        width="inherit-parent-width"
        maxWidth="max-width-528px"
        boxShadow="none"
        minWidth="none"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div
          style={{ minWidth: "fit-content" }}
          className={`${
            props.auth?.theme === "dark"
              ? "background-color-dark"
              : "background-white"
          } width-80-percent border-radius-default`}
        >
          <form
            className="display-flex flex-direction-column padding-larger"
            onChange={(event) => {
              setFormData({
                ...formData,
                [event.target.name]: event.target.value,
              });
              if (!isTrackingDataPresent) {
                setIsTrackingDataPresent(true);
              }
            }}
            autoComplete="off"
            onSubmit={(event) => {
              event.preventDefault();
              props.setTracksData(
                formData?.phoneNumber,
                +new Date(formData.date)
              );
            }}
          >
            <div
              className={`font-size-medium text-align-center  ${
                props.auth?.theme === "dark" ? "font-color-white" : ""
              } `}
            >
              PROVIDE DETAILS TO TRACK EMPLOYEE
            </div>
            <InputBox
              className="inherit-parent-width padding-top-larger"
              name="phoneNumber"
              label="PHONE NUMBER"
              type="phoneNumber"
              removeResponsive={true}
              autoComplete="off"
              theme={props.auth?.theme}
              fontColor={props.auth?.theme === "dark" ? "font-color-white" : ""}
              labelFontColor={
                props.auth?.theme === "dark" ? "font-color-white" : ""
              }
              value={formData?.phoneNumber}
              data-cy="phoneNumber-field"
              required
            />
            <InputBox
              className="inherit-parent-width padding-top-larger"
              name="date"
              label="Date"
              type="date"
              theme={props.auth?.theme}
              removeResponsive={true}
              autoComplete="off"
              fontColor={props.auth?.theme === "dark" ? "font-color-white" : ""}
              labelFontColor={
                props.auth?.theme === "dark" ? "font-color-white" : ""
              }
              value={formData.date}
              data-cy="date-field"
              required
            />

            {!isTrackingDataPresent && (
              <div className="display-flex flex-align-items-center">
                <div
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                >
                  <InfoIcon color="red" />
                </div>

                <div
                  style={{
                    color: "red",
                  }}
                  className="padding-left-default"
                >
                  NO TRACKING DETAILS FOUND FOR THIS EMPLOYEE
                </div>
              </div>
            )}

            {formData.date > new Date().toISOString().split("T")[0] && (
              <div className="display-flex flex-align-items-center font-size-small">
                <div
                  style={{
                    color: "red",
                  }}
                  className="padding-left-default"
                >
                  EARLIEST ACCEPTABLE TIME SHOULD BE THE PRESENT TIME
                </div>
              </div>
            )}

            <Button
              data-cy={
                props.track?.loading ? "track-loading-button" : "track-button"
              }
              loading={props.track?.loading}
              type="submit"
              className="margin-top-larger"
              text="Track"
              disabled={
                formData?.phoneNumber &&
                formData?.phoneNumber.length === 13 &&
                formData?.date &&
                !(formData?.date > new Date().toISOString().split("T")[0]) &&
                isTrackingDataPresent
                  ? false
                  : true
              }
              boxShadow={false}
            />
          </form>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = function(state) {
  return {
    auth: state.auth,
    tracks: state.tracks,
  };
};

const mapDispatchToProps = function() {
  return {
    setTracksData: (phoneNumber, timestamp) =>
      setTracksData(phoneNumber, timestamp),
    clearTracksData: () => clearTracksData(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationTracking);
function calculateDistance(location1, location2) {
  const deg2rad = (deg) => deg * (Math.PI / 180);

  if (location1 && location2) {
    const R = 6371;
    const dLat = deg2rad(location2.lat - location1.lat);
    const dLon = deg2rad(location2.lon - location1.lon);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(location1.lat)) *
        Math.cos(deg2rad(location2.lat)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = Math.round(R * c * 1000);
    return distance;
  }
  return 0;
}

function calculateAcceleration(distance, time1, time2) {
  const deltaTime = (time2 - time1) / 1000;
  return distance / (deltaTime * deltaTime);
}

export function filterData(dataArray, minDistanceThreshold) {
  if (dataArray.length < 2) {
    return dataArray;
  }

  let filteredData = [dataArray[0]];

  for (let i = 1; i < dataArray.length - 1; i++) {
    const prevPoint = dataArray[i - 1];
    const currPoint = dataArray[i];
    const nextPoint = dataArray[i + 1];

    const prevDistance = calculateDistance(
      { lat: parseFloat(prevPoint.lat), lon: parseFloat(prevPoint.lon) },
      { lat: parseFloat(currPoint.lat), lon: parseFloat(currPoint.lon) }
    );

    const nextDistance = calculateDistance(
      { lat: parseFloat(currPoint.lat), lon: parseFloat(currPoint.lon) },
      { lat: parseFloat(nextPoint.lat), lon: parseFloat(nextPoint.lon) }
    );

    const prevAcceleration = calculateAcceleration(
      prevDistance,
      prevPoint.timestamp,
      currPoint.timestamp
    );
    const nextAcceleration = calculateAcceleration(
      nextDistance,
      currPoint.timestamp,
      nextPoint.timestamp
    );

    if (
      !(
        prevAcceleration < nextAcceleration &&
        nextAcceleration > prevAcceleration
      )
    ) {
      if (prevDistance >= minDistanceThreshold) {
        filteredData.push(currPoint);
      }
    }
  }

  filteredData.push(dataArray[dataArray.length - 1]);

  return filteredData;
}

const removeMinimumDistance = (dataArray, minDistanceThreshold) => {
  let filteredData = [dataArray[0]];

  for (let i = 1; i < dataArray.length - 1; i++) {
    const currPoint = dataArray[i];
    const nextPoint = dataArray[i + 1];

    const dist = calculateDistance(
      { lat: parseFloat(currPoint.lat), lon: parseFloat(currPoint.lon) },
      { lat: parseFloat(nextPoint.lat), lon: parseFloat(nextPoint.lon) }
    );

    if (dist >= minDistanceThreshold) {
      filteredData.push(currPoint);
    }
  }

  filteredData.push(dataArray[dataArray.length - 1]);

  return filteredData;
};

function InfoCard(props) {
  return (
    <div
      data-cy="location-tracker-info-card"
      style={{
        width: "400px",
      }}
      className="display-flex info-card-box-shadow border-1px-e5e5e5 font-color-white flex-direction-column flex-align-items-center  font-size-larger background-color-dark padding-large border-radius-32px"
      onClick={props.onEditClick}
    >
      <div className="corner top-left" />
      <div className="corner top-right" />
      <div className="corner bottom-left" />
      <div className="corner bottom-right" />
      <div className="font-size-medium padding-bottom-large">
        EMPLOYEE INFORMATION
      </div>
      <div className="display-flex inherit-parent-width flex-align-items-center flex-justify-content-space-between">
        <div className="display-flex flex-direction-column">
          <div className="font-size-medium text-uppercase">
            {props.data?.phoneNumber}, {props.data?.name}
          </div>
          <div className="font-size-medium padding-top-default">
            {dateAndTimeConverter(props.formData.date, "secondDate")}
          </div>
        </div>
        <div>
          <ProcedureEditIcon color="white" />
        </div>
      </div>
    </div>
  );
}

const snapToRoad = (path, stopover) => {
  return new Promise((resolve, reject) => {
    const pathStr = path
      .map((point) => `${point.location?.lat},${point.location?.lng}`)
      .join("|");

    const url = `https://roads.googleapis.com/v1/snapToRoads?path=${pathStr}&interpolate=true&key=${process.env.REACT_APP_MAP_KEY}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.snappedPoints) {
          const snappedCoordinates = data.snappedPoints.map((point) => ({
            location: {
              lat: point.location.latitude,
              lng: point.location.longitude,
            },
            stopover: stopover,
          }));
          resolve(snappedCoordinates);
        } else {
          reject("No snapped points found");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

function selectElements(array) {
  const n = array.length;
  const result = [];

  if (n <= 100) {
    return array;
  }

  result.push(array[0]);

  const middleCount = 90 - 2;
  const step = (n - 2) / middleCount;

  for (let i = 1; i <= middleCount; i++) {
    result.push(array[Math.round(i * step)]);
  }

  result.push(array[n - 1]);

  return result;
}

// const getValidDistance = (data) => {
//   if (data.length < 20) {
//     return 0;
//   }
//   if (data.length < 20 && data.length > 40) {
//     return 40;
//   }
//   if (data.length < 20 && data.length > 80) {
//     return 80;
//   } else {
//     return 80;
//   }
// };

const createCustomOverlay = (map, position, content) => {
  let div = null;

  const overlay = new window.google.maps.OverlayView();

  overlay.onAdd = () => {
    div = document.createElement("div");
    div.style.position = "absolute";
    div.style.width = "240px";
    div.style.height = "fit-content";
    div.style.padding = "16px";
    div.style.color = "white";
    div.style.backgroundColor = "#020d23";
    div.style.border = "solid 1px grey";

    div.innerHTML = content;

    const panes = overlay.getPanes();
    panes.overlayLayer.appendChild(div);
  };

  overlay.draw = () => {
    const overlayProjection = overlay.getProjection();
    const pixelPosition = overlayProjection.fromLatLngToDivPixel(position);

    if (div) {
      div.style.left = `${pixelPosition.x}px`;
      div.style.top = `${pixelPosition.y}px`;
    }
  };

  overlay.onRemove = () => {
    if (div) {
      div.parentNode.removeChild(div);
      div = null;
    }
  };

  overlay.setMap(map);
  return overlay;
};

const content = (name, phoneNumber, timestamp) => {
  return `<div style="z-index:1;font-family:aldrich-regular;">
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; top: -1px; left: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; top: -1px; right: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; bottom: -1px; left: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; bottom: -1px; right: -1px;"></div>
      <div style="display:flex">
        <div style="padding-right:8px">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" fill="#565656" fill-opacity="0.3" />
            <path
              d="M19.8948 18.5919C18.8792 16.7442 17.2933 15.3045 15.3924 14.5045C16.3377 13.7696 17.0359 12.745 17.3882 11.5759C17.7406 10.4068 17.7291 9.15238 17.3554 7.99039C16.9818 6.8284 16.2649 5.81774 15.3063 5.10156C14.3477 4.38538 13.1961 4 12.0144 4C10.8328 4 9.68116 4.38538 8.72258 5.10156C7.76401 5.81774 7.04711 6.8284 6.67345 7.99039C6.29979 9.15238 6.28831 10.4068 6.64063 11.5759C6.99295 12.745 7.69121 13.7696 8.6365 14.5045C6.73562 15.3045 5.14971 16.7442 4.13407 18.5919C4.07114 18.6968 4.0292 18.8138 4.01073 18.9358C3.99227 19.0578 3.99767 19.1825 4.0266 19.3023C4.05554 19.4221 4.10742 19.5347 4.17916 19.6333C4.25091 19.7319 4.34105 19.8146 4.44423 19.8764C4.54741 19.9382 4.66153 19.9779 4.77979 19.993C4.89805 20.0082 5.01805 19.9986 5.13265 19.9647C5.24725 19.9308 5.35411 19.8734 5.44688 19.7959C5.53965 19.7184 5.61643 19.6223 5.67266 19.5134C7.01495 17.1084 9.38543 15.674 12.0144 15.674C14.6434 15.674 17.0139 17.1092 18.3562 19.5134C18.4781 19.7165 18.6714 19.8625 18.8952 19.9206C19.1191 19.9787 19.356 19.9443 19.5558 19.8246C19.7556 19.7049 19.9027 19.5094 19.9659 19.2794C20.0292 19.0493 20.0037 18.8028 19.8948 18.5919ZM8.16241 9.83815C8.16241 9.04842 8.38832 8.27643 8.81159 7.61979C9.23486 6.96315 9.83646 6.45136 10.5403 6.14915C11.2442 5.84693 12.0187 5.76786 12.7659 5.92193C13.5132 6.076 14.1995 6.45629 14.7382 7.01471C15.2769 7.57313 15.6438 8.28461 15.7925 9.05917C15.9411 9.83372 15.8648 10.6366 15.5732 11.3662C15.2817 12.0958 14.788 12.7194 14.1545 13.1582C13.521 13.5969 12.7763 13.8311 12.0144 13.8311C10.9932 13.8299 10.0141 13.4088 9.29194 12.6602C8.5698 11.9117 8.16358 10.8968 8.16241 9.83815Z"
              fill="white"
            />
          </svg>
        </div>
        <div style="padding-bottom:16px">
          <div style="text-transform:uppercase;font-size:10px;">${name}</div>
          <div style="padding-top:4px;font-size:14px;">${phoneNumber}</div>
        </div>
      </div>

      <div style="display: flex; justify-content: space-between;">
        <div style="display: flex; align-items: center; flex-direction: column;">
          <div style="font-size:10px;">TIME</div>
          <div style="padding-top:8px;">
            ${dateAndTimeConverter(timestamp, "Time")}
          </div>
        </div>
        <div style="display: flex; align-items: center; flex-direction: column;">
          <div style="font-size:10px;" >DIRECTION</div>

          <div style="transform: rotate(180deg);padding-top:8px;">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4564 7.5L2.02231 7.5C1.88394 7.5 1.75125 7.55267 1.65341 7.64643C1.55557 7.74018 1.50061 7.86735 1.50061 7.99994C1.50061 8.13253 1.55557 8.25969 1.65341 8.35345C1.75125 8.4472 1.88394 8.49987 2.02231 8.49987H12.4564C12.5947 8.49987 12.7274 8.4472 12.8253 8.35345C12.9231 8.25969 12.9781 8.13253 12.9781 7.99994C12.9781 7.86735 12.9231 7.74018 12.8253 7.64643C12.7274 7.55267 12.5947 7.5 12.4564 7.5Z"
                fill="white"
              />
              <path
                d="M12.2405 8L7.91349 12.1455C7.81553 12.2394 7.76049 12.3667 7.76049 12.4994C7.76049 12.6322 7.81553 12.7595 7.91349 12.8534C8.01145 12.9473 8.14431 13 8.28285 13C8.42139 13 8.55426 12.9473 8.65222 12.8534L13.3475 8.35396C13.3961 8.30752 13.4347 8.25235 13.461 8.19161C13.4873 8.13087 13.5008 8.06576 13.5008 8C13.5008 7.93424 13.4873 7.86913 13.461 7.80839C13.4347 7.74765 13.3961 7.69248 13.3475 7.64604L8.65222 3.14661C8.55426 3.05274 8.42139 3 8.28285 3C8.14431 3 8.01145 3.05274 7.91349 3.14661C7.81553 3.24049 7.76049 3.36781 7.76049 3.50057C7.76049 3.63333 7.81553 3.76065 7.91349 3.85452L12.2405 8Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div style="display: flex; align-items: center; flex-direction: column;">
          <div style="font-size:10px;" >DATE</div>
          <div style="padding-top:8px;">
            ${dateAndTimeConverter(timestamp, "secondDate")}
          </div>
        </div>
      </div>
    </div>`;
};

function generateAestheticColor() {
  const goldenRatioConjugate = 0.61803398875;

  let hue = Math.random();
  hue += goldenRatioConjugate;
  hue %= 1;

  const saturation = 60 + Math.random() * 20;
  const lightness = 70 + Math.random() * 10;

  hue = Math.floor(hue * 360);

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
