import React from "react";
import BarChart from "../../Components/Graphs/BarChart";
import { DoughnutChart } from "../../Components/Graphs/DoughnutChart";
import { CornerLIcon, TableIcon } from "../../Assets/assets";
import footerImage from "../../Assets/TableFooter.svg";

export default function ComplaintSummary(props) {
  const getStatusChartData = (count) => {
    return {
      labels: ["CLOSED", "UNDER REVIEW", "OPEN"],
      datasets: [
        {
          label: "Complaint status",
          data: [count?.closed || 0, count?.review || 0, count?.open || 0],
          backgroundColor: [
            count?.closed > 0 ? "rgba(76, 100, 255, 0.5)" : "#c2c2c2",
            count?.review > 0 ? "rgba(217, 217, 217, 0.5)" : "#c2c2c2",
            count?.open > 0 ? "rgba(255, 108, 108, 0.5)" : "#c2c2c2",
          ],
          borderColor: [
            count?.closed > 0 ? "rgba(76, 100, 255, 1)" : "#c2c2c2",
            count?.review > 0 ? "rgba(217, 217, 217, 1)" : "#c2c2c2",
            count?.open > 0 ? "rgba(255, 108, 108, 1)" : "#c2c2c2",
          ],
          borderWidth: 1,
          spacing: 10,
        },
      ],
      defaultValue: `TOTAL: ${count?.closed +
        count?.review +
        count?.open} COMPLAINTS`,
    };
  };

  const getRaisedChartData = (count) => {
    return {
      labels: ["INTERNAL", "CUSTOMER SUPPORT", "PUBLIC"],
      datasets: [
        {
          label: "Complaint issued by status",
          data: [
            count?.internal || 0,
            count?.customerSupport || 0,
            count?.public || 0,
          ],
          backgroundColor: [
            count?.internal > 0 ? "rgba(76, 100, 255, 0.5)" : "#c2c2c2",
            count?.customerSupport > 0 ? "rgba(217, 217, 217, 0.5)" : "#c2c2c2",
            count?.public > 0 ? "rgba(255, 108, 108, 0.5)" : "#c2c2c2",
          ],
          borderColor: [
            count?.internal > 0 ? "rgba(76, 100, 255, 1)" : "#c2c2c2",
            count?.customerSupport > 0 ? "rgba(217, 217, 217, 1)" : "#c2c2c2",
            count?.public > 0 ? "rgba(255, 108, 108, 1)" : "#c2c2c2",
          ],
          borderWidth: 2,
          spacing: 10,
        },
      ],
      defaultValue: `TOTAL: ${count?.internal +
        count?.customerSupport +
        count?.public} COMPLAINTS`,
    };
  };

  const getMonthlyComparisonBarChartData = (count) => {
    return {
      labels: count.map((data) => data.month),
      datasets: [
        {
          label: "OPEN",
          backgroundColor: "rgba(255, 108, 108, 0.5)",
          borderColor: "rgba(255, 108, 108, 1)",
          borderWidth: 2,
          data: count.map((data) => data.count.open),
          barThickness: 16,
        },
        { weight: 0.2 },
        {
          label: "CLOSED",
          backgroundColor: "rgba(76, 100, 255, 0.5)",
          borderColor: "rgba(76, 100, 255, 1)",
          borderWidth: 2,
          data: count.map((data) => data.count.closed),
          barThickness: 16,
        },
      ],
      defaultValue: `AVERAGE: ${(
        count
          .map((data) => data.count.total)
          .reduce((sum, value) => sum + value, 0) / count.length
      ).toFixed(0)} COMPLAINTS`,
    };
  };

  const getLongestDurationBarChartData = (complaints) => {
    return {
      labels: complaints.map((data) => data.documentId),
      datasets: [
        {
          label: "Dataset 1",
          backgroundColor: "rgba(217, 217, 217, 0.5)",
          borderColor: "rgba(217, 217, 217, 1)",
          borderWidth: 2,
          data: complaints.map((data) => data.duration.hours),
          barThickness: 16,
        },
      ],
      defaultValue: `AVERAGE: ${(
        complaints
          .map((data) => data.duration.hours)
          .reduce((sum, value) => sum + value, 0) / complaints.length ||
        0 / 3600000
      ).toFixed(2) || 0} HR (s)`,
    };
  };

  const getClosedDurationBarChartData = (count) => {
    console.log(count?.average, "count?.average");
    return {
      labels: ["<1 DAY", "1-3 DAYS", "3-7 DAYS", ">7 DAYS"],
      datasets: [
        {
          label: "Dataset 1",
          backgroundColor: "rgba(217, 217, 217, 0.5)",
          borderColor: "rgba(217, 217, 217, 1)",
          borderWidth: 2,
          // eslint-disable-next-line array-callback-return
          data: Object.keys(count).map((key) => {
            if (key !== "average") {
              return count[key];
            }
          }),
          barThickness: 16,
        },
      ],
      defaultValue: `AVERAGE: ${count?.average} HR (S)`,
    };
  };

  return (
    <div
      className="inherit-parent-height remaining-body-height-from-header flex-justify-content-center flex-align-items-center inherit-parent-width overflow-scroll"
      style={{
        minWidth: "80%",
      }}
    >
      <div
        style={{
          minWidth: "100%",
        }}
        className="inherit-parent-height inherit-parent-width padding-larger display-flex flex-justify-content-center flex-align-items-center"
      >
        <div className="inherit-parent-width">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridAutoRows: "1fr",
            }}
            className="display-flex flex-justify-content-center flex-align-items-center"
          >
            {props.summaryData.map((data, index) => (
              <div
                key={index}
                style={{
                  minWidth: "200px",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    height: "8px",
                  }}
                  className="display-flex flex-justify-content-space-between flex-align-items-center"
                >
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      transform: "translate(-2px,-0.5px)",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      transform: "rotate(90deg)",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                </div>
                <div
                  style={{
                    minHeight: "200px",
                    maxHeight: "250px",
                    padding: "32px",
                  }}
                  className="flex-align-items-center flex-justify-content-center flex-align-items-center position-relative responsive-graph-section-height"
                >
                  {data.title === "COMPLAINTS RAISED DATA" && (
                    <DoughnutChart
                      units={"COMPLAINTS"}
                      title={data.title}
                      data={getRaisedChartData(data.count)}
                      legend={
                        <>
                          <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                            <div className="display-flex">
                              <div
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  backgroundColor: "rgba(76, 100, 255, 0.5)",
                                  border: "solid 1px rgba(76, 100, 255, 1)",
                                  marginLeft: "16px",
                                  marginRight: "16px",
                                  padding: "6px",
                                }}
                              />
                              <div>INTERNAL - {data.count.internal || 0}</div>
                            </div>
                          </div>
                          <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                            <div className="display-flex">
                              <div
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  backgroundColor: "rgba(255, 108, 108, 0.5)",
                                  border: "solid 1px rgba(255, 108, 108, 1)",
                                  marginLeft: "16px",
                                  marginRight: "16px",
                                  padding: "6px",
                                }}
                              />
                              <div>PUBLIC - {data.count.public || 0}</div>
                            </div>
                          </div>
                          <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                            <div className="display-flex">
                              <div
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  backgroundColor: "rgba(217, 217, 217, 0.5)",
                                  border: "solid 1px rgba(217, 217, 217, 1)",
                                  marginLeft: "16px",
                                  marginRight: "16px",
                                  padding: "6px",
                                }}
                              />
                              <div>
                                SUPPORT - {data.count.customerSupport || 0}{" "}
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    />
                  )}

                  {data.title === "COMPLAINTS STATUS DATA" && (
                    <DoughnutChart
                      units={"COMPLAINTS"}
                      title={data.title}
                      data={getStatusChartData(data.count)}
                      legend={
                        <>
                          <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                            <div className="display-flex">
                              <div
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  backgroundColor: "rgba(76, 100, 255, 0.5)",
                                  border: "solid 1px rgba(76, 100, 255, 1)",
                                  marginLeft: "16px",
                                  marginRight: "16px",
                                  padding: "6px",
                                }}
                              />
                              <div>CLOSED - {data.count.closed || 0}</div>
                            </div>
                          </div>
                          <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                            <div className="display-flex">
                              <div
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  backgroundColor: "rgba(255, 108, 108, 0.5)",
                                  border: "solid 1px rgba(255, 108, 108, 1)",
                                  marginLeft: "16px",
                                  marginRight: "16px",
                                  padding: "6px",
                                }}
                              />
                              <div>OPEN - {data.count.open || 0}</div>
                            </div>
                          </div>
                          <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                            <div className="display-flex">
                              <div
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  backgroundColor: "rgba(217, 217, 217, 0.5)",
                                  border: "solid 1px rgba(217, 217, 217, 1)",
                                  marginLeft: "16px",
                                  marginRight: "16px",
                                  padding: "6px",
                                }}
                              />
                              <div>REVIEW - {data.count.review || 0} </div>
                            </div>
                          </div>
                        </>
                      }
                    />
                  )}

                  {data.title === "COMPLAINTS MONTHLY COMPARISON" && (
                    <BarChart
                      title={data.title}
                      data={getMonthlyComparisonBarChartData(data.count)}
                    />
                  )}

                  {data.title === "LONGEST DURATION COMPLAINTS" && (
                    <BarChart
                      units={"HR (s)"}
                      title={data.title}
                      data={getLongestDurationBarChartData(data.complaints)}
                    />
                  )}

                  {data.title === "COMPLAINTS CLOSURE DURATION" && (
                    <BarChart
                      units={"COMPLAINTS (s)"}
                      title={data.title}
                      data={getClosedDurationBarChartData(data.count)}
                    />
                  )}

                  {data.title === "MAX COMPLAINTS RAISED LOCATIONS" && (
                    <div className=" ">
                      <div className="overlay font-size-medium">
                        COMING SOON...
                      </div>
                      <ProgressChart title="POSITIVE PROCEDURES" />
                      <ProgressChart title="POSITIVE PROCEDURES" />
                      <ProgressChart title="POSITIVE PROCEDURES" />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    height: "8px",
                  }}
                  className="display-flex flex-justify-content-space-between flex-align-items-center"
                >
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      transform: "rotate(270deg) translate(-2px,-2.6px)",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      transform: "rotate(180deg) translate(0px,-3px)",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          minWidth: "700px",
          width: "75%",
          position: "absolute",
          bottom: "60px",
        }}
      >
        <div className="position-relative inherit-parent-width">
          <div className="position-absolute inherit-parent-width display-flex flex-justify-content-space-between">
            <div
              style={{
                height: "44px",
              }}
              className="padding-left-large font-size-medium display-flex  flex-align-items-center"
            >
              {/* {props.falseData || `DISPLAYING ${props.hits?.length} RECORDS`} */}
            </div>
            <div
              style={{
                height: "44px",
              }}
              onClick={props.summaryClick}
              className="padding-right-large font-size-medium display-flex flex-align-items-center cursor-pointer"
            >
              <div
                style={{ height: "12px", width: "12px" }}
                className="margin-right-default"
              >
                <TableIcon height="12" width="12" />
              </div>
              <div>TABLE &nbsp; &nbsp; &nbsp; &nbsp; </div>
            </div>
          </div>

          <img
            style={{
              minHeight: "44px",
              height: "auto",
              width: "100%",
              objectFit: "fill",
            }}
            src={footerImage}
            alt="footerImage"
          />
        </div>
      </div>
    </div>
  );
}

const ProgressChart = (props) => {
  return (
    <div>
      <div className="padding-bottom-small font-size-small">{props.title}</div>
      <div className=" display-flex">
        <div className="padding-bottom-default margin-right-large">
          <CornerLIcon width={6} height={6} />
        </div>
        {Array.from({ length: 60 }, (_, index) => (
          <div
            key={index}
            style={{
              height: "12px",
              transform: "rotate(45deg)",
              padding: "0.7px",
            }}
            className={`margin-right-smaller margin-top-large margin-bottom-large animated-progress ${
              index <= (40 / 100) * 60
                ? "background-color-light-blue"
                : "background-white"
            }`}
          />
        ))}
        <div
          className="margin-left-large"
          style={{
            transform: "rotate(180deg)",
          }}
        >
          <CornerLIcon width={6} height={6} />
        </div>
      </div>
    </div>
  );
};
