import React, { useEffect, useRef } from "react";

import { Bar } from "react-chartjs-2";
import "./Graphs.css";
// import { stripeBarChartPlugin } from "../../Screens/Test/stripePlugin";

export default function BarChart(props) {
  const customToolTip = useRef(null);

  const customOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        callbacks: {
          label: function(context) {
            const label = context.label || "";
            const value = context.raw || "";
            return `${label}: ${value || 0} ${props.units ||
              context.dataset.label + " COMPLAINTS"}`;
          },
        },
        position: "nearest",
        external: function(context) {
          const tooltip = context.tooltip;
          const chart = context.chart;
          const chartPosition = chart.canvas.getBoundingClientRect();

          if (!customToolTip.current) {
            customToolTip.current = document.createElement("div");
            customToolTip.current.style.width = `${chartPosition.width}px`;
            customToolTip.current.style.position = "absolute";
            customToolTip.current.style.fontSize = "12px";
            customToolTip.current.style.whiteSpace = "no-wrap";
            customToolTip.current.style.display = "flex";
            customToolTip.current.style.flexDirection = "column";
            customToolTip.current.style.justifyContent = "center";
            customToolTip.current.style.alignItems = "center";
            customToolTip.current.style.color = "#fff";
            customToolTip.current.style.padding = "5px";
            customToolTip.current.style.borderRadius = "3px";
            customToolTip.current.style.pointerEvents = "none";
            document.body.appendChild(customToolTip.current);
          }

          customToolTip.current.innerHTML = tooltip.body
            ?.map((bodyItem) => bodyItem.lines)
            .join("<br>");

          customToolTip.current.style.left = `${chartPosition.left +
            window.pageXOffset}px`;
          customToolTip.current.style.top = `${chartPosition.bottom +
            window.pageYOffset +
            16}px`;

          if (tooltip.opacity === 0) {
            customToolTip.current.innerHTML = props.data.defaultValue;
          }
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: true,
          borderColor: "white",
          borderWidth: 2,
        },
        ticks: {
          display: true,
          color: "white",
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: true,
          borderColor: "white",
          borderWidth: 2,
        },
        ticks: {
          display: true,
          color: "white",
        },
      },
    },
    interaction: {
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  useEffect(() => {
    if (customToolTip.current && props.data.defaultValue) {
      customToolTip.current.style.fontSize = "12px";

      customToolTip.current.innerHTML = props.data.defaultValue;
    }
    // eslint-disable-next-line
  }, [props.data.defaultValue]);

  return (
    <div className="inherit-parent-height inherit-parent-width flex-align-items-center flex-justify-content-center flex-direction-column position-relative">
      <div
        style={{
          position: "absolute",
          width: "100%",
          justifyContent: "center",
          display: "flex",
          top: "-24px",
        }}
        className="font-size-medium text-align-center"
      >
        {props.title}
      </div>
      <div className="height-90-percentage inherit-parent-width margin-top-larger display-flex flex-align-items-centerq">
        <Bar
          data={props.data}
          options={customOptions}
          // plugins={[stripeBarChartPlugin]}
          style={{
            minWidth: "100%",
            minHeight: "100%",
          }}
          className="inherit-parent-width height-width"
        />
      </div>
      <div
        ref={customToolTip}
        style={{
          paddingTop: "20px",
          marginBottom: "16px",
        }}
      />
    </div>
  );
}
