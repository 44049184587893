import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AddIcon, CloseIcon, NoImageProvidedIcon } from "../../Assets/assets";
import { isValidArray, isValidObject } from "../../Services/validators";
import AudioPlayer from "../AudioPlayer/AudioPlayer";

export default function CarouselAndAudioPlayer(props) {
  const [close, setClose] = useState(false);

  const handleRemoveImage = (proofSrc) => {
    setClose(true);
    props.setCroppedImage({
      images: props?.fileSrc?.images?.filter((data) => data !== proofSrc),
    });
    setClose(false);
  };

  useEffect(() => {
    if (close) {
      props.onImageClick(null);
    }
    // eslint-disable-next-line
  }, [close]);

  const getImageSrc = (proofSrc, add) => {
    if (add && proofSrc instanceof File) {
      try {
        return URL.createObjectURL(proofSrc);
      } catch (error) {
        console.error("Error creating object URL:", error);
        return null;
      }
    }
    return proofSrc;
  };

  return (
    <div
      className="inherit-parent-height width-fit-content display-flex flex-direction-column flex-align-items-center flex-justify-content-center"
      data-cy={`${props.dataCy}-carousel-component`}
    >
      {isValidArray(props?.fileSrc?.images) && (
        <div className="cursor-pointer display-flex">
          <Carousel
            autoPlay={false}
            centerSlidePercentage={50}
            infiniteLoop={false}
            onClickItem={(_index, item) => {
              if (!close) {
                props.onImageClick(
                  item?.props?.children?.props?.children?.[1]?.props?.src
                );
              }
            }}
            selectedItem={props.add ? props?.fileSrc?.images?.length - 1 : null}
            width="290px"
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
          >
            {props?.fileSrc?.images?.map(
              (proofSrc, index) =>
                proofSrc && (
                  <div
                    data-cy={`${props.dataCy}-proof-${index + 1}`}
                    key={index}
                    className="display-flex flex-align-items-center flex-justify-content-center"
                  >
                    <div
                      style={{
                        width: "241.8px",
                      }}
                    >
                      {props.add && (
                        <div
                          className="display-flex flex-justify-content-center flex-align-items-center cursor-pointer"
                          onClick={() => {
                            handleRemoveImage(proofSrc);
                          }}
                          onMouseEnter={() => {
                            setClose(true);
                          }}
                          onMouseLeave={() => {
                            setClose(false);
                          }}
                          style={{
                            borderRadius: "100%",
                            width: 18,
                            zIndex: 1,
                            height: 18,
                            backgroundColor: "black",
                            position: "absolute",
                            top: 4,
                            right: 34,
                          }}
                        >
                          <CloseIcon height="12" width="12" color="white" />
                        </div>
                      )}
                      <img
                        className="margin-right-large"
                        src={getImageSrc(proofSrc, props.add)}
                        alt={`item-${index}`}
                        style={{
                          width: "241.8px",
                          height: "108px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                )
            )}
          </Carousel>

          {props.fileSrc?.images?.length <= 4 && props.add && (
            <form
              className="inherit-parent-width padding-left-default"
              onChange={(event) => {
                if (event.target.files) {
                  if (
                    ["image/png", "image/jpeg", "image/jpg"].includes(
                      event.target.files[0]?.type
                    ) === false
                  ) {
                    return props.setErrorStatus({
                      code: "custom",
                      message: "Kindly upload a valid File",
                    });
                  }

                  if (
                    ["image/png", "image/jpeg", "image/jpg"].includes(
                      event.target.files[0]?.type
                    ) === true
                  ) {
                    props.setFile({
                      ...props.file,
                      image: [
                        ...props.file.image,
                        URL.createObjectURL(event.target.files[0]),
                      ],
                    });
                  }
                }
              }}
            >
              <div className="height-width-56px margin-bottom-default cursor-pointer">
                <label>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    name={"fileUploaderInput"}
                    onClick={(event) => {
                      event.target.value = "";
                    }}
                    className="visibility-hidden position-absolute"
                  />
                  <div
                    className="padding-bottom-medium display-flex flex-justify-content-center flex-align-items-center background-color-grey-C2C2C2"
                    style={{
                      width: "241.8px",
                      height: "108px",
                      backgroundColor: "#1C2535",
                    }}
                    onClick={() => {}}
                    data-cy="Add-image-icon"
                    key="add-icon"
                  >
                    <AddIcon color="white" />
                  </div>
                </label>
              </div>
            </form>
          )}
        </div>
      )}

      {!isValidObject(props?.fileSrc) && !props.add && (
        <div
          className="display-flex flex-justify-content-center flex-align-items-center"
          style={{
            width: "300px",
          }}
          data-cy={`${props.dataCy}-no-image-provided-icon`}
        >
          <div
            className="display-flex flex-justify-content-center flex-align-items-center background-color-grey-C2C2C2 cursor-no-drop"
            style={{
              width: "241.8px",
              height: "108px",
              backgroundColor: "#1C2535",
            }}
          >
            {props.complaintsAssetsLoading ? (
              <div
                data-cy="assets-loading"
                className="background-color-white padding-left-default padding-right-default padding-top-small padding-bottom-small border-radius-default "
              >
                <div className="qr-loader" data-cy="qr-loader" />
              </div>
            ) : (
              <NoImageProvidedIcon />
            )}
          </div>
        </div>
      )}
      {isValidObject(props?.fileSrc) &&
        typeof props?.fileSrc?.audio === "string" &&
        !props.add && (
          <AudioPlayer
            className={`inherit-parent-width padding-vertical-small ${
              typeof props.fileSrc?.audio !== "string"
                ? "cursor-no-drop"
                : "cursor-pointer"
            }`}
            src={
              isValidObject(props?.fileSrc) &&
              typeof props.fileSrc?.audio === "string"
                ? props.fileSrc?.audio
                : ""
            }
            theme={props.theme}
            dataCy={`${props.dataCy}-audio-player`}
          />
        )}
      {props?.fileSrc?.images?.length === 0 &&
        [null, undefined].includes(props?.fileSrc?.audio) &&
        !props.add && <div data-cy="no-assets-provided"> N/A </div>}
      {props?.fileSrc?.images?.length === 0 &&
        [null, undefined].includes(props?.fileSrc?.audio) &&
        props.add && (
          <form
            className="inherit-parent-width padding-left-default"
            onChange={(event) => {
              if (event.target.files) {
                if (
                  ["image/png", "image/jpeg", "image/jpg"].includes(
                    event.target.files[0]?.type
                  ) === false
                ) {
                  return props.setErrorStatus({
                    code: "custom",
                    message: "Kindly upload a valid File",
                  });
                }

                if (
                  ["image/png", "image/jpeg", "image/jpg"].includes(
                    event.target.files[0]?.type
                  ) === true
                ) {
                  props.setFile({
                    ...props.file,
                    image: [
                      ...props.file.image,
                      URL.createObjectURL(event.target.files[0]),
                    ],
                  });
                }
              }
            }}
          >
            <div className="height-width-56px margin-bottom-default cursor-pointer">
              <label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  name={"fileUploaderInput"}
                  onClick={(event) => {
                    event.target.value = "";
                  }}
                  className="visibility-hidden position-absolute"
                />
                <div
                  className="padding-bottom-medium display-flex flex-justify-content-center flex-align-items-center background-color-grey-C2C2C2"
                  style={{
                    width: "241.8px",
                    height: "108px",
                    backgroundColor: "#1C2535",
                  }}
                  onClick={() => {}}
                  data-cy="Add-image-icon"
                  key="add-icon"
                >
                  <AddIcon color="white" />
                </div>
              </label>
            </div>
          </form>
        )}
    </div>
  );
}
