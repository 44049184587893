import React, { useMemo } from "react";
import { DoughnutChart } from "../../Components/Graphs/DoughnutChart";
import { CornerLIcon, TableIcon } from "../../Assets/assets";
import footerImage from "../../Assets/TableFooter.svg";
import BarChart from "../Graphs/BarChart";
// import { useEffect } from "react";
// import { useState } from "react";

export default function LocationSummary(props) {
  const locationDataTotals = useMemo(() => {
    return props.hits.reduce(
      (acc, item) => {
        acc.optimal += item.status === "Optimal" || 0;
        acc.moderate += item.status === "Moderate" || 0;
        acc.critical += item.status === "Critical" || 0;
        return acc;
      },
      {
        optimal: 0,
        moderate: 0,
        critical: 0,
      }
    );
  }, [props.hits]);

  const sampleBarChartData = {
    labels: ["Jan", "Feb", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        backgroundColor: "white",
        borderColor: "black",
        borderWidth: 0,
        data: [65, 59, 80, 81, 56, 55, 40],
        barThickness: 16,
      },
    ],
  };

  const doughnutChartData = {
    labels: ["OPTIMAL", "MODERATE", "CRITICAL"],
    datasets: [
      {
        label: "Complaint status",
        data: [
          locationDataTotals.optimal || 0,
          locationDataTotals.moderate || 0,
          locationDataTotals.critical || 0,
        ],

        backgroundColor: [
          locationDataTotals.optimal > 0
            ? "rgba(76, 100, 255, 0.5)"
            : "#c2c2c2",
          locationDataTotals.moderate > 0
            ? "rgba(217, 217, 217, 0.5)"
            : "#c2c2c2",
          locationDataTotals.critical > 0
            ? "rgba(255, 108, 108, 0.5)"
            : "#c2c2c2",
        ],
        borderColor: [
          locationDataTotals.optimal > 0 ? "rgba(76, 100, 255, 1)" : "#c2c2c2",
          locationDataTotals.moderate > 0
            ? "rgba(217, 217, 217, 1)"
            : "#c2c2c2",
          locationDataTotals.critical > 0
            ? "rgba(255, 108, 108, 1)"
            : "#c2c2c2",
        ],
        borderWidth: 2,
        spacing: 10,
      },
    ],
    defaultValue: `TOTAL: ${locationDataTotals.optimal +
      locationDataTotals.moderate +
      locationDataTotals.critical} PCT (s)`,
  };

  const sampleDoughnutChartData = {
    labels: ["OPTIMAL", "MODERATE", "CRITICAL"],
    datasets: [
      {
        label: "Status Data",
        data: [
          locationDataTotals.optimal || 40,
          locationDataTotals.moderate || 30,
          locationDataTotals.critical || 30,
        ],

        backgroundColor: [
          locationDataTotals.optimal > 40
            ? "rgba(76, 100, 255, 0.5)"
            : "#c2c2c2",
          locationDataTotals.moderate > 30
            ? "rgba(217, 217, 217, 0.5)"
            : "#c2c2c2",
          locationDataTotals.critical > 30 ? "#FF6C6C" : "#c2c2c2",
        ],
        borderColor: [
          locationDataTotals.optimal > 40 ? "rgba(76, 100, 255, 1)" : "#c2c2c2",
          locationDataTotals.moderate > 30
            ? "rgba(217, 217, 217, 1)"
            : "#c2c2c2",

          locationDataTotals.critical > 30
            ? "rgba(255, 108, 108, 1)"
            : "#c2c2c2",
        ],
        borderWidth: 2,
        spacing: 10,
      },
    ],
  };

  return (
    <div
      className="inherit-parent-height remaining-body-height-from-header flex-justify-content-center flex-align-items-center inherit-parent-width overflow-scroll"
      style={{
        minWidth: "80%",
      }}
    >
      <div
        style={{
          minWidth: "100%",
        }}
        className="inherit-parent-height inherit-parent-width padding-larger flex-justify-content-center flex-align-items-center"
      >
        <div className="inherit-parent-width">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridAutoRows: "1fr",
            }}
            className="display-flex flex-justify-content-center flex-align-items-center"
          >
            {props.summaryData.map((data, index) => (
              <div
                key={index}
                style={{
                  minWidth: "200px",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    height: "8px",
                  }}
                  className="display-flex flex-justify-content-space-between flex-align-items-center "
                >
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      transform: "translate(-2px,-0.5px)",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      transform: "rotate(90deg)",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                </div>
                <div
                  style={{
                    minHeight: "200px",
                    height: "250px",
                    maxHeight: "300px",
                  }}
                  className="flex-align-items-center flex-justify-content-center flex-align-items-center padding-larger position-relative"
                >
                  {data.title === "MANPOWER" && (
                    <div className="width-80-percentage position-relative">
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                          top: "-62px",
                        }}
                        className="font-size-medium text-align-center"
                      >
                        {data.title}
                      </div>
                      <ManpowerProgressChart
                        title="MANPOWER"
                        count={data.count.manpower || 0}
                        showPercentage={true}
                        className="padding-bottom-larger"
                      />
                      <ManpowerProgressChart
                        type="negative"
                        title="LAST EMPLOYEE CAPTURE"
                        count={data.count.lastEmployeeCapture || 0}
                        showPercentage={true}
                      />
                    </div>
                  )}

                  {data.title === "KPI" && (
                    <>
                      <div className="overlay font-size-medium">
                        COMING SOON...
                      </div>
                      <DoughnutChart
                        title={data.title}
                        data={sampleDoughnutChartData}
                        legend={
                          <>
                            <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                              <div className="display-flex">
                                <div
                                  style={{
                                    height: "12px",
                                    width: "12px",
                                    backgroundColor: "rgba(76, 100, 255, 0.5)",
                                    border: "solid 1px rgba(76, 100, 255, 1)",
                                    marginLeft: "16px",
                                    marginRight: "16px",
                                    padding: "6px",
                                  }}
                                />
                                <div>CLOSED - {data.count.closed || 0}</div>
                              </div>
                            </div>
                            <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                              <div className="display-flex">
                                <div
                                  style={{
                                    height: "12px",
                                    width: "12px",
                                    backgroundColor: "rgba(255, 108, 108, 0.5)",
                                    border: "solid 1px rgba(255, 108, 108, 1)",
                                    marginLeft: "16px",
                                    marginRight: "16px",
                                    padding: "6px",
                                  }}
                                />
                                <div>OPEN - {data.count.open || 0}</div>
                              </div>
                            </div>
                            <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                              <div className="display-flex">
                                <div
                                  style={{
                                    height: "12px",
                                    width: "12px",
                                    backgroundColor: "rgba(217, 217, 217, 0.5)",
                                    border: "solid 1px rgba(217, 217, 217, 1)",
                                    marginLeft: "16px",
                                    marginRight: "16px",
                                    padding: "6px",
                                  }}
                                />
                                <div>REVIEW - {data.count.review || 0} </div>
                              </div>
                            </div>
                          </>
                        }
                      />
                    </>
                  )}

                  {data.title === "PENALTY" && (
                    <>
                      <div className="overlay font-size-medium">
                        COMING SOON...
                      </div>
                      <BarChart title={data.title} data={sampleBarChartData} />
                    </>
                  )}

                  {data.title === "KPI HISTORY" && (
                    <>
                      <div className="overlay font-size-medium">
                        COMING SOON...
                      </div>
                      <BarChart title={data.title} data={sampleBarChartData} />
                    </>
                  )}

                  {data.title === "PENALTY HISTORY" && (
                    <>
                      <div className="overlay font-size-medium">
                        COMING SOON...
                      </div>
                      <DoughnutChart
                        title={data.title}
                        data={sampleDoughnutChartData}
                        legend={
                          <>
                            <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                              <div className="display-flex">
                                <div
                                  style={{
                                    height: "12px",
                                    width: "12px",
                                    backgroundColor: "rgba(76, 100, 255, 0.5)",
                                    border: "solid 1px rgba(76, 100, 255, 1)",
                                    marginLeft: "16px",
                                    marginRight: "16px",
                                    padding: "6px",
                                  }}
                                />
                                <div>CLOSED - {data.count.closed || 0}</div>
                              </div>
                            </div>
                            <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                              <div className="display-flex">
                                <div
                                  style={{
                                    height: "12px",
                                    width: "12px",
                                    backgroundColor: "rgba(255, 108, 108, 0.5)",
                                    border: "solid 1px rgba(255, 108, 108, 1)",
                                    marginLeft: "16px",
                                    marginRight: "16px",
                                    padding: "6px",
                                  }}
                                />
                                <div>OPEN - {data.count.open || 0}</div>
                              </div>
                            </div>
                            <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                              <div className="display-flex">
                                <div
                                  style={{
                                    height: "12px",
                                    width: "12px",
                                    backgroundColor: "rgba(217, 217, 217, 0.5)",
                                    border: "solid 1px rgba(217, 217, 217, 1)",
                                    marginLeft: "16px",
                                    marginRight: "16px",
                                    padding: "6px",
                                  }}
                                />
                                <div>REVIEW - {data.count.review || 0} </div>
                              </div>
                            </div>
                          </>
                        }
                      />
                    </>
                  )}

                  {data.title === "STATUS DATA" && (
                    <DoughnutChart
                      units="PCT (s)"
                      title={data.title}
                      data={doughnutChartData}
                      legend={
                        <>
                          <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                            <div className="display-flex">
                              <div
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  backgroundColor: "rgba(76, 100, 255, 0.5)",
                                  border: "solid 1px rgba(76, 100, 255, 1)",
                                  marginLeft: "16px",
                                  marginRight: "16px",
                                  padding: "6px",
                                }}
                              />
                              <div>
                                OPTIMAL - {locationDataTotals.optimal || 0}
                              </div>
                            </div>
                          </div>
                          <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                            <div className="display-flex">
                              <div
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  backgroundColor: "rgba(255, 108, 108, 0.5)",
                                  border: "solid 1px rgba(255, 108, 108, 1)",
                                  marginLeft: "16px",
                                  marginRight: "16px",
                                  padding: "6px",
                                }}
                              />
                              <div>
                                CRITICAL - {locationDataTotals.critical || 0}
                              </div>
                            </div>
                          </div>
                          <div className="padding-bottom-default white-space-no-wrap font-size-medium">
                            <div className="display-flex">
                              <div
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  backgroundColor: "rgba(217, 217, 217, 0.5)",
                                  border: "solid 1px rgba(217, 217, 217, 1)",
                                  marginLeft: "16px",
                                  marginRight: "16px",
                                  padding: "6px",
                                }}
                              />
                              <div>
                                MODERATE - {locationDataTotals.moderate || 0}{" "}
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    />
                  )}
                </div>
                <div
                  style={{
                    height: "8px",
                  }}
                  className="display-flex flex-justify-content-space-between flex-align-items-center"
                >
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      transform: "rotate(270deg) translate(-2px,-2.6px)",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      transform: "rotate(180deg) translate(0px,-3px)",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          minWidth: "700px",
          width: "75%",
          position: "absolute",
          bottom: "60px",
        }}
      >
        <div className="position-relative inherit-parent-width">
          <div className="position-absolute inherit-parent-width display-flex flex-justify-content-space-between">
            <div
              style={{
                height: "44px",
              }}
              className="padding-left-large font-size-medium display-flex  flex-align-items-center"
            >
              {props.falseData || `DISPLAYING ${props.hits?.length} RECORDS`}
            </div>
            <div
              style={{
                height: "44px",
              }}
              onClick={props.summaryClick}
              className="padding-right-large font-size-medium display-flex flex-align-items-center cursor-pointer"
            >
              <div className="padding-right-default">
                <TableIcon />
              </div>
              <div>TABLE &nbsp; &nbsp; &nbsp; &nbsp; </div>
            </div>
          </div>

          <img
            style={{
              minHeight: "44px",
              height: "auto",
              width: "100%",
              objectFit: "fill",
            }}
            src={footerImage}
            alt="footerImage"
          />
        </div>
      </div>
    </div>
  );
}

// const ManpowerProgressChart = (props) => {
//   const [arrayLength, setArrayLength] = useState(60);

//   const updateArrayLength = () => {
//     const screenWidth = window.innerWidth;
//     if (screenWidth <= 1800) {
//       setArrayLength(40);
//     } else if (screenWidth <= 1920) {
//       setArrayLength(60);
//     } else {
//       setArrayLength(60);
//     }
//   };

//   useEffect(() => {
//     updateArrayLength();
//     window.addEventListener("resize", updateArrayLength);

//     return () => {
//       window.removeEventListener("resize", updateArrayLength);
//     };
//   }, []);

//   return (
//     //old one
//     <div className={props.className}>
//       <div className="padding-bottom-large font-size-medium">{`${props.title} ${
//         props.showPercentage ? `: ${props.count}%` : ""
//       }`}</div>
//       <div className=" display-flex">
//         <div className="padding-bottom-large margin-right-large">
//           <CornerLIcon width={8} height={8} />
//         </div>
//         {Array.from({ length: arrayLength }, (_, index) => (
//           <div
//             key={index}
//             style={{
//               height: "16px",
//               transform: "rotate(45deg)",
//               padding: "1px",
//             }}
//             className={`margin-right-small margin-top-large margin-bottom-large animated-progress ${
//               index <= (props.count / 100) * arrayLength
//                 ? "background-white"
//                 : "background-color-red"
//             }`}
//           />
//         ))}
//         <div
//           className="margin-left-large"
//           style={{
//             transform: "rotate(180deg)",
//           }}
//         >
//           <CornerLIcon width={8} height={8} />
//         </div>
//       </div>
//     </div>
//   );
// };

const ManpowerProgressChart = (props) => {
  return (
    <div className={props.className}>
      <div className="padding-bottom-large font-size-medium">{`${props.title} ${
        props.showPercentage ? `: ${props.count}%` : ""
      }`}</div>

      <div
        style={{
          width: "100%",
          height: "20px",
          border: "1px solid rgba(217, 217, 217, 1)",
        }}
      >
        <div
          style={{
            width: `${props.count}%`,
            height: "20px",
            backgroundColor: "rgba(76, 100, 255, 0.5)",
          }}
        ></div>
      </div>
    </div>
  );
};
