import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./Redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import LogRocket from "logrocket";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import UnsupportedDevice from "./Components/UnsupportedDevice/UnsupportedDevice";
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

// eslint-disable-next-line new-parens
((g) => {
  var h,
    a,
    k,
    p = "The Google Maps JavaScript API",
    c = "google",
    l = "importLibrary",
    q = "__ib__",
    m = document,
    b = window;
  b = b[c] || (b[c] = {});
  var d = b.maps || (b.maps = {}),
    r = new Set(),
    e = new URLSearchParams(),
    u = () =>
      h ||
      (h = new Promise(async (f, n) => {
        await (a = m.createElement("script"));
        e.set("libraries", [...r] + "");
        for (k in g)
          e.set(
            k.replace(/[A-Z]/g, (t) => "_" + t[0].toLowerCase()),
            g[k]
          );
        e.set("callback", c + ".maps." + q);
        a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
        d[q] = f;
        a.onerror = () => (h = n(Error(p + " could not load.")));
        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
        m.head.append(a);
      }));
  d[l]
    ? console.warn(p + " only loads once. Ignoring:", g)
    : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)));
})({
  key: process.env.REACT_APP_MAP_KEY,
  v: "weekly",
});

ReactDOM.render(
  <ErrorBoundary
    onClick={() => {
      localStorage.clear();
      window.location.reload();
    }}
    handleComponentDidCatch={(error) => {
      LogRocket.captureMessage(error.message, {
        tags: {
          userType: "admin",
        },
        extra: {
          fatal: true,
          code: error.code,
        },
      });
    }}
  >
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <InstantSearch searchClient={searchClient} indexName="LOCATIONS">
              {(process.env.REACT_APP_STAGING === "development" &&
                !window.Cypress) ||
              !navigator.userAgent.includes("Mobile") ? (
                <App />
              ) : (
                <UnsupportedDevice />
              )}
            </InstantSearch>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

if (window.Cypress) {
  window.store = store;
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register({
    onUpdate: onSWUpdate,
    onSuccess: (_controller, buildNo) => {
      console.log(`Successfully updated and serving build ${buildNo}`);
    },
  });
}

const skipWaitingClearCacheAndReload = async () => {
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data === "FORCE_UPDATE_COMPLETE") {
      window.location.reload(true);
    }
  });

  navigator.serviceWorker.ready.then((registration) => {
    registration.waiting.postMessage("FORCE_UPDATE");
  });
};

async function onSWUpdate(controller, force) {
  if (force === true && controller) {
    const criticalUpdateCard = document.getElementById("critical-update-card");
    criticalUpdateCard.classList.remove("display-none");
    criticalUpdateCard.classList.add("display-block");
    const criticalUpdateButton = document.getElementById(
      "critical-update-confirm-button"
    );
    criticalUpdateButton.addEventListener("click", () =>
      skipWaitingClearCacheAndReload()
    );
  }
}

reportWebVitals();
